
import React, { useState} from 'react';
import { Link } from "react-router-dom";
import Canvas from "./Canvas";
import ProcessRequest from './ProcessRequest';

interface MyComponentProps {
    // Add any prop types here if needed
}

const Home: React.FC<MyComponentProps> = (props) => {


    const [prompt, setPrompt] = useState('');

    const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(e.target.value);
    }


    return (
                            
<body className="home-three dark-body">
    <div className="page-wrapper">

       {/* Preloader 
        <div className="preloader"></div>*/}


       {/*====== Header Part Start ======*/}
        <header className="main-header header-three">
           {/*Header-Upper*/}
            <div className="header-upper">
                <div className="container clearfix">
                    <div className="header-inner py-20">
                        <div className="logo-outer">
                            <div className="logo"><a href="index.html"><h2>OnItsWay</h2></a></div>
                        </div>

                        <div className="nav-outer d-flex align-items-center clearfix mx-lg-auto">
                           {/* Main Menu */}
                            <nav className="main-menu navbar-expand-lg">
                                <div className="navbar-header">
                                    <div className="logo-mobile"><a href="index.html"><img src="assets/images/logos/logo-white.png" alt="Logo"/></a></div>
                                   {/* Toggle Button */}
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target=".navbar-collapse" aria-controls="main-menu">
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                </div>

                                <div className="navbar-collapse collapse clearfix" id="main-menu">
                                    <ul className="navigation clearfix">
                                        <li><a className="scroll-to-target"  data-target="#home">home</a></li>
                                       {/*<li className="dropdown current"><a href="#">home</a>
                                            <ul>
                                                <li><a href="index.html">Home One</a></li>
                                                <li><a href="index2.html">Home Two</a></li>
                                                <li><a href="index3.html">Home Three</a></li>
                                                <li><a href="index3dark.html">Home 3 Dark</a></li>
                                            </ul>
                                        </li>*/}
                                        <li><a className="scroll-to-target"  data-target="#pricing">pricing</a></li>
                                        <li><a className="scroll-to-target"  data-target="#features">features</a></li>
                                        <li><a className="scroll-to-target"  data-target="#testimonials">testimonials</a></li>
                                        <li><a className="scroll-to-target"  data-target="#examples">examples</a></li>
                                       {/*<li className="dropdown"><a href="#">project</a>
                                            <ul>
                                                <li><a href="projects.html">Projects</a></li>
                                                <li><a href="project-details.html">Project details</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown"><a href="#">blog</a>
                                            <ul>
                                                <li><a href="blog.html">blog standard</a></li>
                                                <li><a href="blog-details.html">blog details</a></li>
                                            </ul>
                                        </li>
                                        <li className="dropdown"><a href="#">pages</a>
                                            <ul>
                                                <li><a href="single-service.html">service single</a></li>
                                                <li><a href="team-details.html">Team Profile</a></li>
                                            </ul>
                                        </li>*/}
                                       {/*<li><a href="contact.html">contact</a></li>*/}
                                    </ul>
                                </div>

                            </nav>
                            
                           {/* Main Menu End*/}
                           {/* Menu Serch Box*/}
                          {/* <div className="nav-search">
                                <button className="fa fa-search"></button>
                                <form action="#" className="hide">
                                    <input type="text" placeholder="Search" className="searchbox" required=""/>
                                    <button type="submit" className="searchbutton fa fa-search"></button>
                                </form>
                            </div>*/}
                            
                        </div>

                        <div className="menu-right d-none d-lg-flex align-items-center">
                            <a href="contact.html" className="login">Login <i className="fas fa-lock"></i></a>
                            <a href="contact.html" className="theme-btn style-two">sign up <i className="fas fa-arrow-right"></i></a>
                        </div>

                    </div>
                </div>
            </div>
           {/*End Header Upper*/}

        </header>
       {/*====== Header Part End ======*/}


       {/*====== Hero Section Start ======*/}
        <section id="home" className="hero-section-three rel z-2 pt-235 rpt-150 pb-130 rpb-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-11">
                        <div className="hero-content-three rpt-15 rmb-75">
                            <h1 className="mb-25 wow fadeInUp delay-0-2s">Transform your vision into a site.</h1>
                            <h2 className="wow fadeInUp delay-0-4s"> No coding needed, just pure magic!</h2>
                           {/*<h3 className="mb-55 wow fadeInUp delay-0-4s">Turn chats into websites with our AI. Share your vision, and watch it come alive online. </h3>*/}

                           
                           {/*<form className="newsletter-form mt-40" action="#">
                                <div className="newsletter-email wow fadeInUp delay-0-6s">
                                    <input type="email" placeholder="Enter Email Address" required/>
                                    <button type="submit">Get Free Trial <i className="fas fa-arrow-right"></i></button>
                                </div>
                                <div className="newsletter-radios wow fadeInUp delay-0-8s">
                                    <div className="custom-control custom-radio">
                                      <input type="radio" className="custom-control-input" id="hero-wekly" name="example1" checked/>
                                      <label className="custom-control-label" for="hero-wekly">Wekly Updates</label>
                                    </div> 
                                    <div className="custom-control custom-radio">
                                      <input type="radio" className="custom-control-input" id="hero-monthly" name="example1"/>
                                      <label className="custom-control-label" for="hero-monthly">Monthly Updates</label>
                                    </div> 
                                </div>
                            </form>*/}
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className = "user-input">
                            <div className="newsletter-email wow fadeInUp delay-0-6s">
                            <textarea 
                                placeholder="Create a landing page for a daycare center."
                                onChange={handleTextareaChange}
                            ></textarea>
                            </div>

                            <div>
                                <Link to={`/Canvas?prompt=${encodeURIComponent(prompt)}`} className="theme-btn style-one">
                                    Generate Site <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape"/>
            <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
            <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape"/>
        </section>
       {/*====== Hero Section End ======*/}


       {/*====== Solutions Section Start ======*/}
     {/*  <section className="solutions-section-three text-white text-center rel bg-blue pt-130 rpt-100 z-1 pb-75 rpb-45" style="background-image: url(assets/images/shapes/solutions-bg-dots.png);">
            <div className="container">
               <div className="row justify-content-center">
                   <div className="col-xl-6 col-lg-8 col-md-10">
                       <div className="section-title mb-75">
                            <span className="sub-title">Our Special Solutions</span>
                            <h2>We Customize Your Software to The Smart Ways</h2>
                        </div>
                   </div>
               </div>
                <div className="row justify-content-center">
                    <div className="col-xl-4 col-md-6">
                        <div className="solution-item-three wow fadeInUp delay-0-2s">
                            <i className="far fa-compass"></i>
                            <h3><a href="single-service.html">Software Model</a></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque</p>
                            <a href="single-service.html" className="read-more">Learn More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="solution-item-three wow fadeInUp delay-0-4s">
                            <i className="far fa-map"></i>
                            <h3><a href="single-service.html">Network Wired</a></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque</p>
                            <a href="single-service.html" className="read-more">Learn More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="solution-item-three wow fadeInUp delay-0-6s">
                            <i className="fas fa-shield-alt"></i>
                            <h3><a href="single-service.html">Final Testylization</a></h3>
                            <p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium totam rem aperiam eaque</p>
                            <a href="single-service.html" className="read-more">Learn More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        */}
       {/*====== Solutions Section End ======*/}

       
       {/*====== About Section Start ======*/}
       {/*<section className="about-section-three rel z-1 pt-130 rpt-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-7 col-lg-6">
                        <div className="about-image rmb-55 wow fadeInLeft delay-0-2s">
                            <img src="assets/images/about/about-two.png" alt="About"/>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-6">
                        <div className="about-content-three wow fadeInRight delay-0-2s">
                            <div className="section-title mb-25">
                                <span className="sub-title">Our Special Solutions</span>
                                <h2>Use Landingly to Create Amazing Landing Pages</h2>
                            </div>
                            <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusan tium doloremque laudantium totam rem aperiam eaque quae ainvtore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur</p>
                            <ul className="list-style-one mt-25 mb-35">
                                <li>Customers Website</li>
                                <li>Software Doct</li>
                            </ul>
                            <a href="about.html" className="theme-btn style-three">Get Started <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    */}
       {/*====== About Section End ======*/}

       
       {/*====== Browser Support Start ======*/}
      {/* <section className="browswr-support-section rel z-1 py-130 rpy-100">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-xl-5 col-lg-6">
                        <div className="browswr-support-content rmb-55 wow fadeInRight delay-0-2s">
                            <div className="section-title">
                                <span className="sub-title">Browser Support</span>
                                <h2>We Build Into Your Favorite Web Browser Support</h2>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="solution-item-two">
                                        <i className="fas fa-check"></i>
                                        <h4>Track your Customers From your Website</h4>
                                        <p>Sed ut perspiciatis unde omnis natus error voluptatem accusaente dolorem laudantium totam</p>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="solution-item-two color-two">
                                        <i className="fas fa-check"></i>
                                        <h4>Understand Much Sells Performance Better</h4>
                                        <p>Sed ut perspiciatis unde omnis natus error voluptatem accusaente dolorem laudantium totam</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                        <div className="browswr-support-image text-lg-right wow fadeInLeft delay-0-2s">
                            <img src="assets/images/about/borwser-support.jpg" alt="Browser Support"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        */}
       {/*====== Browser Support End ======*/}
        
        
       

       
       {/*====== Services Section Start ======*/}
        <section id="features" className="services-section-three bg-lighter rel z-1  pb-100 rpb-70">
            <div className="container">
               <div className="row justify-content-center text-center">
                   <div className="col-xl-7 col-lg-8 col-md-10">
                       <div className="section-title mt-100 rmt-70 mb-55">
                            <span className="sub-title">Features</span>
                            <h2>Global hosting, smart tracking, and seamless integrations simplified.</h2>
                        </div>
                   </div>
               </div>
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-0-2s">
                            <i className="flaticon-file"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Well Organization</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-0-4s">
                            <i className="flaticon-responsive-design"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Responsive Design</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-0-6s">
                            <i className="flaticon-security"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Heigh Security</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-0-8s">
                            <i className="flaticon-puzzle"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Creative Design</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-1-0s">
                            <i className="flaticon-badge"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Clean Development</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="service-item wow fadeInUp delay-1-2s">
                            <i className="flaticon-analytics"></i>
                            <div className="content">
                                <h3><a href="single-service.html">Advanced Analytics</a></h3>
                                <p>Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium totam</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Services Section End ======*/}
        
        
        
        
       {/*====== Pricing Section Start ======*/}
        <section id="pricing" className="pricing-section rel z-1  pt-100 rpt-100 pb-100 rpb-70">
            <div className="container">
                <div className="row justify-content-center">
                   <div className="col-xl-7 col-lg-8 col-md-10">
                       <div className="section-title text-center text-white mb-45">
                            <span className="sub-title">Pricing Packages</span>
                            <h2>Make The Wise Decision For Software Solutions</h2>
                        </div>
                   </div>
               </div>
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-item wow fadeInUp delay-0-2s">
                            <h5 className="price-title">Basic Plan</h5>
                            <span className="price">19.85</span>
                            <p>Sed ut perspiciatis unde omnis natus error voluptatem</p>
                            <a href="contact.html" className="theme-btn">Choose Package <i className="fas fa-long-arrow-alt-right"></i></a>
                            <ul className="list-style-one">
                                <li>30-day Free Trial Plan</li>
                                <li>Software Solutions</li>
                                <li>Unique Design</li>
                                <li>24/7 Supports</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-item wow fadeInUp delay-0-4s">
                            <h5 className="price-title">Standard Plan</h5>
                            <span className="price">49.85</span>
                            <p>Sed ut perspiciatis unde omnis natus error voluptatem</p>
                            <a href="contact.html" className="theme-btn">Choose Package <i className="fas fa-long-arrow-alt-right"></i></a>
                            <ul className="list-style-one">
                                <li>30-day Free Trial Plan</li>
                                <li>Software Solutions</li>
                                <li>Unique Design</li>
                                <li>24/7 Supports</li>
                                <li>Include Web Developer</li>
                                <li>750 GB Hosting Free</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="pricing-item wow fadeInUp delay-0-4s">
                            <h5 className="price-title">Gold Plan</h5>
                            <span className="price">99.85</span>
                            <p>Sed ut perspiciatis unde omnis natus error voluptatem</p>
                            <a href="contact.html" className="theme-btn">Choose Package <i className="fas fa-long-arrow-alt-right"></i></a>
                            <ul className="list-style-one">
                                <li>30-day Free Trial Plan</li>
                                <li>Software Solutions</li>
                                <li>Unique Design</li>
                                <li>24/7 Supports</li>
                                <li>Include Web Developer</li>
                                <li>Best UI/UX Design</li>
                                <li>Modern Code</li>
                                <li>750 GB Hosting Free</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <img className="dots-shape" src="assets/images/shapes/white-dots.png" alt="Shape"/>
            <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
            <img className="close-shape" src="assets/images/shapes/yellow-close.png" alt="Shape"/>
            <div className="left-circles"></div>
            <div className="right-circles"></div>
        </section>
       {/*====== Pricing Section End ======*/}

        {/*====== Newsletter Section Start ======*/}
         <section className="newsletter-section-two mt-30 rmt-0 rel z-2">
            <div className="container">
                <div className="newsletter-inner style-two bg-gray bgs-cover text-white rel z-1">
                    <div className="row align-items-center align-items-xl-start">
                        <div className="col-lg-6">
                            <div className="newsletter-content p-60 wow fadeInUp delay-0-2s">
                                <div className="section-title mb-30">
                                    <span className="sub-title">Our Newsletter</span>
                                    <h2>Subscribe Our Newsletter to Get More Updates</h2>
                                </div>
                                <form className="newsletter-form" action="#">
                                    <div className="newsletter-email">
                                        <input type="email" placeholder="Enter Email Address" required/>
                                        <button type="submit">Sign up <i className="fas fa-angle-right"></i></button>
                                    </div>
                                    <div className="newsletter-radios">
                                        <div className="custom-control custom-radio">
                                          <input type="radio" className="custom-control-input" id="wekly" name="example1" checked/>
                                          <label className="custom-control-label" htmlFor="wekly">Wekly Updates</label>
                                        </div> 
                                        <div className="custom-control custom-radio">
                                          <input type="radio" className="custom-control-input" id="monthly" name="example1"/>
                                          <label className="custom-control-label" htmlFor="monthly">Monthly Updates</label>
                                        </div> 
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="newsletter-images wow fadeInUp delay-0-4s">
                                <img src="assets/images/newsletter/newsletter-two.png" alt="Newsletter"/>
                                <img src="assets/images/newsletter/circle.png" alt="shape" className="circle slideUpRight"/>
                                <img src="assets/images/newsletter/dots.png" alt="shape" className="dots slideLeftRight"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Newsletter Section End ======*/}

       {/*====== Dashboard Section Start ======*/}
        <section className="dashboard-section rel z-1 py-130 rpy-100">
            <div className="container">
               <div className="row justify-content-center text-center">
                   <div className="col-xl-6 col-lg-8 col-md-10">
                       <div className="section-title mb-60">
                            <span className="sub-title">Dashboard Screenshot</span>
                            <h2>Let’s See Awesome Software UI Design Scrrenshot</h2>
                        </div>
                   </div>
               </div>
                <div className="dashboard-screenshot-wrap">
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot1.jpg" alt="Dashboard Screenshot"/>
                    </div>
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot2.jpg" alt="Dashboard Screenshot"/>
                    </div>
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot3.jpg" alt="Dashboard Screenshot"/>
                    </div>
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot1.jpg" alt="Dashboard Screenshot"/>
                    </div>
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot2.jpg" alt="Dashboard Screenshot"/>
                    </div>
                    <div className="dashboard-screenshot-item">
                        <img src="assets/images/dashboard-screenshots/dashboard-screenshot3.jpg" alt="Dashboard Screenshot"/>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Dashboard Section End ======*/}

        
       {/*====== Feedback Section Start ======*/}
        <section id="testimonials" className="feedback-section-three bg-blue rel z-1 py-115 rpy-85" style={{backgroundImage: "url(assets/images/feedbacks/feedback-bg.png)"}}>
            <div className="container">
               <div className="row">
                    <div className="col-xl-4">
                        <div className="feedback-three-content mt-15 text-white wow fadeInUp delay-0-2s">
                            <div className="section-title mb-25">
                                <span className="sub-title">Clients Feedback</span>
                                <h2>What Our Customer ’s Say About Us</h2>
                            </div>
                            <p>Sed ut perspiciatis unde omnis natus error voluptatem accusantium doloremque laudantium totam rem aperias eaque quae ainvtore veritatis et quasi architecto beatae vitae dicta sunt explicabo enim spsam</p>
                            <div className="slider-dots-area mt-25"></div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="feedback-three-wrap">
                            <div className="feedback-item-two wow fadeInUp delay-0-4s">
                                <img src="assets/images/client-logos/client-logo-white1.png" alt="Logo"/>
                                <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                                <div className="feedback-author">
                                    <i className="flaticon-quote-1"></i>
                                    <div className="author-content">
                                        <h3>Randall L. Hamby</h3>
                                        <span>Senior Manager</span>
                                    </div>
                                </div>
                            </div>
                            <div className="feedback-item-two wow fadeInUp delay-0-6s">
                                <img src="assets/images/client-logos/client-logo-white2.png" alt="Logo"/>
                                <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                                <div className="feedback-author">
                                    <i className="flaticon-quote-1"></i>
                                    <div className="author-content">
                                        <h3>Lillie R. Burkhalter</h3>
                                        <span>Senior Manager</span>
                                    </div>
                                </div>
                            </div>
                            <div className="feedback-item-two wow fadeInUp delay-0-8s">
                                <img src="assets/images/client-logos/client-logo-white3.png" alt="Logo"/>
                                <p>Sed ut perspiciatis unde omniste natus errors voluptatem accusantium doloreue laudantium <b>eaque ipsauae abtore</b> verita architecto beatae vitae dicta sunxplica nemo enimsam voluptatem quia natur autodit aut fugit</p>
                                <div className="feedback-author">
                                    <i className="flaticon-quote-1"></i>
                                    <div className="author-content">
                                        <h3>Zelma K. Hall</h3>
                                        <span>Senior Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Feedback Section End ======*/}

       
       {/*====== Partner Section Start ======*/}
        <div className="partner-area-three text-center pt-130 rpt-100">
            <div className="container">
                <div className="row justify-content-around justify-content-lg-between mb-80 rmb-50">
                    <div className="col-lg-2 col-md-4 col-6">
                        <a className="partner-item-three wow fadeInUp delay-0-2s" href="project-details.html">
                            <img src="assets/images/partners/partner-three-white1.png" alt="Partner"/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <a className="partner-item-three wow fadeInUp delay-0-4s" href="project-details.html">
                            <img src="assets/images/partners/partner-three-white2.png" alt="Partner"/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <a className="partner-item-three wow fadeInUp delay-0-6s" href="project-details.html">
                            <img src="assets/images/partners/partner-three-white3.png" alt="Partner"/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <a className="partner-item-three wow fadeInUp delay-0-8s" href="project-details.html">
                            <img src="assets/images/partners/partner-three-white4.png" alt="Partner"/>
                        </a>
                    </div>
                    <div className="col-lg-2 col-md-4 col-6">
                        <a className="partner-item-three wow fadeInUp delay-1-0s" href="project-details.html">
                            <img src="assets/images/partners/partner-three-white5.png" alt="Partner"/>
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
       {/*====== Partner Section End ======*/}

      
       {/*====== Contact Section Start ======*/}
        <section className="contact-section-two rel z-1 pt-115 rpt-85 pb-130 rpb-55">
            <div className="container">
               <div className="row justify-content-center text-center">
                   <div className="col-xl-6 col-lg-8 col-md-10">
                       <div className="section-title mb-50">
                            <span className="sub-title">Get Free 7 Days Trial</span>
                            <h2>Contact Us to Get Your 7days Free Trial Version</h2>
                        </div>
                   </div>
               </div>
                <div className="contact-information-area pb-50">
                    <div className="row justify-content-center">
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-item wow fadeInUp delay-0-2s">
                                <i className="fas fa-envelope-open"></i>
                                <div className="contact-info-content">
                                    <h3>Email Us</h3>
                                    <span><a href="mailto:support@gmail.com">support@gmail.com</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-item wow fadeInUp delay-0-4s">
                                <i className="fas fa-phone"></i>
                                <div className="contact-info-content">
                                    <h3>Phone</h3>
                                    <span><a href="calto:+0123456789">+012 (345) 67 89</a></span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="contact-info-item wow fadeInUp delay-0-6s">
                                <i className="fas fa-map-marker-alt"></i>
                                <div className="contact-info-content">
                                    <h3>Address</h3>
                                    <span>55 Main Street, USA</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="contact-left-map h-100 pr-xl-5 rpb-20 wow fadeInLeft delay-0-2s">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d136834.1519573059!2d-74.0154445224086!3d40.7260256534837!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1639991650837!5m2!1sen!2sbd" style={{ border: "0", width: "100%", height: "100%" }} allowFullScreen loading="lazy"></iframe>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <form id="contact-form-two" className="contact-form-two py-45 wow fadeInRight delay-0-2s" action="#" method="post">
                            <div className="row clearfix">
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" id="name" name="name" className="form-control" placeholder="full name" required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="email" id="email" name="email" className="form-control" placeholder="Email Address" required/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" id="number" name="number" className="form-control" placeholder="phone number"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" id="company" name="company" className="form-control" placeholder="Company"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="text" id="subject" name="subject" className="form-control" placeholder="subject"/>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="form-group">
                                        <input type="url" id="website" name="website" className="form-control" placeholder="website"/>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mb-4">
                                        <textarea name="message" id="message" rows={4} className="form-control" placeholder="message" required></textarea>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group mb-0">
                                        <button className="theme-btn" type="submit">get free trial <i className="fas fa-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Contact Section End ======*/}
       
       
      {/*====== Blog Section Start ======*/}
        <section className="blog-section-two bg-lighter rel z-1 pt-130 rpt-100 pb-180 rpb-150">
            <div className="container">
               <div className="row">
                    <div className="col-xl-4 col-lg-8 col-md-10">
                        <div className="blog-two-section-content mb-55 wow fadeInUp delay-0-2s">
                            <div className="section-title mb-25">
                                <span className="sub-title">Latest News & Blog</span>
                                <h2>Get Our Every Single Update Latest News and Blog</h2>
                            </div>
                            <a href="blog.html" className="theme-btn style-three">View More News <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="blog-item-two wow fadeInUp delay-0-4s">
                            <div className="blog-two-image" style={{backgroundImage:"url(assets/images/blog/blog-two.jpg)"}}></div>
                            <ul className="blog-meta">
                                <li><i className="far fa-calendar-alt"></i> <a href="blog-details.html">25 March 2022</a></li>
                                <li><i className="far fa-comments"></i> <a href="#">Com (5)</a></li>
                            </ul>
                            <h3><a href="blog-details.html">Smashin Podcast Episode Web Frameworks Solve Vanilla</a></h3>
                            <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized the charms of pleasure</p>
                            <a href="blog-details.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-6">
                        <div className="blog-item-two wow fadeInUp delay-0-6s">
                            <div className="blog-two-image" style={{backgroundImage:"url(assets/images/blog/blog-two.jpg)"}}></div>
                            <ul className="blog-meta">
                                <li><i className="far fa-calendar-alt"></i> <a href="blog-details.html">25 March 2022</a></li>
                                <li><i className="far fa-comments"></i> <a href="#">Com (5)</a></li>
                            </ul>
                            <h3><a href="blog-details.html">Designing Better Links Wesites And Emails A Guideline</a></h3>
                            <p>On the other hand, we denounce with righteous indignation and dislike men who are so beguiled and demoralized the charms of pleasure</p>
                            <a href="blog-details.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
       {/*====== Blog Section End ======*/}
        
       
       {/*====== Footer Section Start ======*/}
        <footer className="footer-section footer-two bg-gray text-white rel z-1">
            <div className="container">
              
               <div className="call-to-action bg-blue bgs-cover text-white rel z-1">
                    <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6">
                            <div className="section-title mb-20">
                                <h2>Have Any Help to Saas Solutions?</h2>
                                <p>Professional service for your software solutions</p>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6">
                            <div className="call-to-action-btns text-xl-right mb-20">
                                <a href="contact.html" className="theme-btn style-two rmb-15">Get Started <i className="fas fa-arrow-right"></i></a>
                                <a href="about.html" className="theme-btn style-three rmb-15">Learn More <i className="fas fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <img className="white-circle" src="assets/images/shapes/white-circle.png" alt="White Circle"/>
                    <img className="white-dots slideUpRight" src="assets/images/shapes/white-dots.png" alt="shape"/>
                    <img className="white-dots-circle slideLeftRight" src="assets/images/shapes/white-dots-circle.png" alt="shape"/>
                </div>
                
                <div className="row justify-content-between">
                    <div className="col-xl-3 col-sm-6 col-7 col-small">
                        <div className="footer-widget about-widget">
                            <div className="footer-logo mb-20">
                                <a href="index.html"><img src="assets/images/logos/logo-white.png" alt="Logo"/></a>
                            </div>
                            <p><span>Sed perspiciatis unde omnis istee sit voluptatem accusante doloreue</span> <b>laudantium</b> <span>aperiam eaque abillo inventore</span></p>
                            <a href="about.html" className="read-more">Read More <i className="fas fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="col-xl-2 col-sm-4 col-5 col-small">
                        <div className="footer-widget link-widget">
                            <h4 className="footer-title">About</h4>
                            <ul className="list-style-two">
                                <li><a href="projects.html">Success History</a></li>
                                <li><a href="team-details.html">Meet The Team</a></li>
                                <li><a href="blog.html">Latest News</a></li>
                                <li><a href="contact.html">Need a Career?</a></li>
                                <li><a href="single-service.html">Popular Service</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-4 col-md-8">
                        <div className="footer-widget link-widget">
                            <h4 className="footer-title">Quick Links</h4>
                            <ul className="list-style-two two-column">
                                <li><a href="single-service.html">Software Solutions</a></li>
                                <li><a href="single-service.html">Market Analysis</a></li>
                                <li><a href="single-service.html">Web Development</a></li>
                                <li><a href="single-service.html">Digital Solutions</a></li>
                                <li><a href="single-service.html">Graphics Design</a></li>
                                <li><a href="single-service.html">Consultations</a></li>
                                <li><a href="single-service.html">Application Design</a></li>
                                <li><a href="single-service.html">Success Rate</a></li>
                                <li><a href="single-service.html">SEO Optimization</a></li>
                                <li><a href="single-service.html">User Research</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-3 col-md-4">
                        <div className="footer-widget contact-widget">
                            <h4 className="footer-title">Get in Touch</h4>
                            <ul className="list-style-three">
                                <li><i className="fas fa-map-marker-alt"></i> <span>55 Main Street, New York</span></li>
                                <li><i className="fas fa-envelope-open"></i> <span><a href="mailto:support@gmail.com">support@gmail.com</a></span></li>
                                <li><i className="fas fa-phone"></i> <span>Call : <a href="callto:+012(345)678">+012 (345) 678</a></span></li>
                            </ul>
                            <div className="social-style-one mt-25">
                                <a href="#"><i className="fab fa-facebook-f"></i></a>
                                <a href="#"><i className="fab fa-twitter"></i></a>
                                <a href="#"><i className="fab fa-linkedin-in"></i></a>
                                <a href="#"><i className="fab fa-instagram"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="copyright-area text-center">
                    <p>© 2022 <a href="index.html">Sland.</a> All rights reserved</p>
                </div>
            </div>
            <img className="dots-shape" src="assets/images/shapes/dots.png" alt="Shape"/>
            <img className="tringle-shape" src="assets/images/shapes/tringle.png" alt="Shape"/>
            <img className="close-shape" src="assets/images/shapes/close.png" alt="Shape"/>
            <img className="circle-shape" src="assets/images/shapes/circle.png" alt="Shape"/>
        </footer>
       {/*====== Footer Section End ======*/}

    </div>
   {/*End pagewrapper*/}


   {/* Scroll Top Button */}
    <button className="scroll-top scroll-to-target" data-target="html"><span className="fa fa-angle-up"></span></button>

        </body>
    );
}

export default Home;
