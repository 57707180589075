 function typeWriter(element, speed = 100) {


    element.classList.remove("typewriter");
}

export function write(){   // Check if the elements with the specified IDs exist
    console.log("loaded write");
    const p1 = document.getElementById('typeThis1');
    const p2 = document.getElementById('typeThis2');

    console.log(p1);
    // If they exist, run the typewriter function on them
    if (p1) typeWriter(p1, 100);
    if (p2) typeWriter(p2, 100);
}
