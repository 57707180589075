import React, { useRef, useEffect, useState } from "react";
import Draggable from "react-draggable";
import ReactDOM from "react-dom";
import "./chatgpt.css";
import HTMLFileRenderer from './HTMLFileRenderer';
import { useLocation } from "react-router-dom"; // Import the useLocation hook


function IframeContent() {
  return <HTMLFileRenderer filePath="http://localhost:59207/api/v1/Page" />;
}

// Define the props type
type CanvasProps = {
  prompt?: string;
};



const Canvas: React.FC<CanvasProps> = (props) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [scale, setScale] = useState(1); // Initial scale is 100%
  const [deviceDimensions, setDeviceDimensions] = useState({
    width: 375,
    height: 812,
  }); // Assuming these as initial dimensions for iPhone 14 Pro Max.

  /*const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const prompt = queryParams.get('prompt');

  const [isLoading, setIsLoading] = useState(true); // Initial loading state


  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === "SET_IFRAME_HEIGHT") {
        const newHeight = event.data.height;
        if (iframeRef.current) {
          iframeRef.current.style.height = `${newHeight}px`;
        }
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  useEffect(() => {
    const iframe = iframeRef.current;
    if (!iframe) return;

    const doc = iframe.contentDocument;
    if (!doc) return;

    const root = doc.createElement("div");
    // doc.body.appendChild(root);

    //ReactDOM.render(<IframeContent />, root);

    return () => {
      ReactDOM.unmountComponentAtNode(root);
    };
  }, []);

  const adjustIframeWidthHeight = () => {
    if (iframeRef.current) {
      const contentDocument = iframeRef.current.contentDocument;
      if (contentDocument) {
        const baseContentWidth = contentDocument.body.scrollWidth;
        const baseContentHeight = contentDocument.body.scrollHeight;

        // Calculate the effective width and height
        const scaledContentWidth = baseContentWidth * 1;
        const scaledContentHeight = baseContentHeight * 1;

        console.log(
          "scaledContentWidth: " +
            scaledContentWidth +
            " scaledContentHeight: " +
            scaledContentHeight
        );

        // Set iframe width and height
        iframeRef.current.style.width = `${scaledContentWidth}px`;
        iframeRef.current.style.height = `${scaledContentHeight}px`;
      }
    }
  };

  const handleWheel = (event: React.WheelEvent<HTMLDivElement>) => {
    if (event.ctrlKey) {
      if (event.deltaY > 0) {
        // Zoom out
        setScale((prevScale) => Math.max(0.1, prevScale - 0.1));
      } else {
        // Zoom in
        setScale((prevScale) => Math.min(2, prevScale + 0.1));
      }
      adjustIframeWidthHeight();
      event.preventDefault();
    }
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      // Check if horizontal scrolling is attempted
      if (Math.abs(event.deltaX) > 0) {
        event.preventDefault();
      }
    };

    const containerElem = containerRef.current;
    if (containerElem) {
      // Attach the event listener
      containerElem.addEventListener("wheel", handleWheel, { passive: false });

      return () => {
        // Cleanup the event listener on unmount
        containerElem.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const [isDragging, setIsDragging] = useState(false);

   // Function to handle iframe load event
   const handleIframeLoad = () => {
    // Hide the loading indicator when the iframe is loaded
    setIsLoading(false);
  };*/

  const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        // Extract "prompt" query parameter from the URL
        const queryParams = new URLSearchParams(location.search);
        const prompt = queryParams.get('prompt') || '';

        const fetchData = async () => {
            try {
                console.log("Calling fetch");
                const response = await fetch(`https://onitsway4.webley.io/api/v1/Page?prompt=${encodeURIComponent(prompt)}`);
                if (!response.ok) {
                    console.log(response);
                    throw new Error('Network response was not ok');
                    
                }
                else
                {   
             /*       const url: string = "https://webley.io/htmlresult.html";
                    console.log("Redirecting to:"+url);
                    // If the fetch was successful, redirect to the hardcoded URL
                    window.location.href = url;
                    console.log(response);*/
                }
                //const data = await response.json();

             
            } catch (error) {
                console.error("There was an error fetching the data", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location]);

  return (
    <body className="home-three dark-body">
      <div className="page-wrapper">
        <header>
          <div className="header-upper">
            <div className=" clearfix">
              <div className="header-inner">
                <div className="logo-outer">
                  <div className="logo">
                    <a href="/">
                    <h2>OnItsWay</h2>
                    </a>
                  </div>
                </div>
                <div className="menu-right d-none d-lg-flex align-items-center">
                  <a href="https://webley.io/htmlresult.html" className="theme-btn style-two">
                    publish<i className="fas fa-arrow-right"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <main className="main-contianer">
        <section className="main-contianer rel z-2">
            {/* Conditionally render loading indicator 
            {isLoading && <div>Loading...</div>}
*/}
            {/* Render iframe content when not loading */}
           {/* {!isLoading && (*/}
           {(
              <div>
                    <div>
                        {isLoading && <div><h1></h1>Loading...</div>}
                    </div>
                  
                    {!isLoading && <div
                      className="device-iphone14promax"
                      style={{
                        top: "20px",
                        left: "50px",
                      }}
                    >
                      <div className="device-content">
   
                      
                        <iframe
                          //onLoad={handleIframeLoad} // Handle iframe load event
                         // src={`https://page-service.santosh-517.workers.dev/api/v1/Page?prompt=${encodeURIComponent(prompt || '')}`}
                         src={`https://webley.io/htmlresult.html`}
                          ref={iframeRef}
                          style={{
                            width: "100%",
                            height: "100%",
                            border: "none",
                            display: "block",
                            transform: `scale(${scale})`,
                            transformOrigin: "top left",
                          }}

                        />
    
                      </div>
                    </div>}
                  
                </div>
            )}
          </section>

        </main>
      </div>
    </body>
  );
}

export default Canvas;
