// ProcessRequest.tsx

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ProcessRequest: React.FC = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const location = useLocation();

    useEffect(() => {
        // Extract "prompt" query parameter from the URL
        const queryParams = new URLSearchParams(location.search);
        const prompt = queryParams.get('prompt') || '';

        const fetchData = async () => {
            try {
                console.log("Calling fetch");
                const response = await fetch(`https://onitsway4.webley.io/api/v1/Page?prompt=${encodeURIComponent(prompt)}`);
                if (!response.ok) {
                    console.log(response);
                    throw new Error('Network response was not ok');
                    
                }
                else
                {   
                    const url: string = "https://webley.io/htmlresult.html";
                    console.log("Redirecting to:"+url);
                    // If the fetch was successful, redirect to the hardcoded URL
                    window.location.href = url;
                    console.log(response);
                }
                const data = await response.json();

             
            } catch (error) {
                console.error("There was an error fetching the data", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [location]);

    return (
        <div>
            {isLoading && <div><h1></h1>Loading...</div>}
        </div>
    );
}

export default ProcessRequest;
