import React, { useRef, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";

import Home from "./Home";
import Canvas from "./Canvas";
import ProcessRequest from "./ProcessRequest";

function App() {

  return (
    <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/index" element={<Home />} />
      <Route path="/canvas" element={<Canvas />} />
      <Route path="/ProcessRequest" element={<ProcessRequest />} />
    </Routes>
  </Router>
 
  );
}

export default App;